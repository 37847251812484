'use strict';

require([
  'foundation',
  'skrollr'
], function(Foundation, skrollr) {
  $(function() {
    $(document).foundation();
    window.skrollr = skrollr;
    var s = window.skrollr.init({
    	forceHeight: false
    });
    if (s.isMobile()) {
    	s.destroy();
	  }
    $("#technology h2").on("click", function() {
      $("a[href='#technology']").click();
    });
    

    $('.portfolioslick').slick({
    	autoplay: true,
    	arrows: true,
      nextArrow: "<button class='fi-arrow-right slick-arrow slick-arrow-right'/>",
      prevArrow: "<button class='fi-arrow-left slick-arrow slick-arrow-left'/>",
    	dots: true
    });
  });
});

define("main.js", function(){});

